import { Button } from "../components/Button.tsx";

export function DonationSection() {
  return (
    <section class="sticky w-full bg-white">
      <div class="px-4 max-w-7xl py-8 space-y-32 first-of-type:pt-16 last-of-type:pb-16 flex flex-col justify-center items-center mx-auto">
        <div class="flex flex-col text-center space-y-4">
          <h2 class="text-5xl font-black">We Need Your Help In This.</h2>
          <p class="text-2xl">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>

        <div class="grid md:grid-cols-2 gap-8 items-center">
          <div class="flex flex-col max-md:text-center gap-6">
            <h2 class="text-5xl font-black">Lorem ipsum dolor sit amet</h2>
            <p class="text-2xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <a href="https://www.zeffy.com/en-US/donation-form/494bf8e0-525f-463c-8ec0-bf1455fcdde6">
              <Button className="p-6 w-full h-full hover:bg-[#ac2b37] border-[#ac2b37] border-2 transition-colors tracking-[0.25em] uppercase text-black font-black">
                DONATE
              </Button>
            </a>
          </div>

          <picture class="max-md:-order-1 mr-6">
            {/*<source srcset="/media/gettyimages-514694906.jpg" />*/}
            <img
              src="/media/gettyimages-514694906.jpg"
              style="box-shadow: rgb(172 43 55) 1.5rem 1.5rem 0"
              class="w-full object-contain"
            />
          </picture>
        </div>

        <div class="flex bg-[#4b0fe8] text-white w-full font-black text-lg p-8 items-center gap-8">
          <p class="text-2xl">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>

          <span class="flex aspect-square p-4 items-center justify-center w-12 h-12 bg-gray-400 rounded-full text-gray-800 text-lg">
            &gt;
          </span>
        </div>
      </div>
    </section>
  );
}
